<style lang="sass" scoped>
$primary: #007bff
.input-field-overlay
  margin-top: -1.7rem
  float: left
  position: relative
  top: 2.3rem
  left: 0.5rem
.form-field
  border: 0
  border-bottom: solid 1px #f0f0f0 !important
  font-weight: bold
  text-align: left
  padding-left: 4rem
  &:focus
    color: $primary
    box-shadow: none !important
.order-color-default
  color: #ccc
  &.order-color-active
    color: tomato
    font-weight: bold !important
    background-color: lighten(tomato, 30%) !important
    border-color: lighten(tomato, 20%) !important
    box-shadow: 0px 0px 10px -2px tomato

    .icon-light
      opacity: 1 !important
      filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)
// .container-menu-backdrop
  position: fixed
  left: 0px
  top: 0px
  width: 100vw
  height: 100vh
  background-color: rgba(0,0,0,0.3)
  z-index: 1
.container-menu
  width: 300
  background-color: #fff
  position: relative
</style>
<template lang="pug">
div.bg-light.p-3
  .row
    .col-md
      form.form(@submit.prevent='submit')
        .bg-white.p-2.border(style='font-size: 12px')
          //- h1(style='color: #ccc') 고객명 입력
          //- p(style='color: #ccc') 고객메모 입력
          h1
            //- | {{order.name}}
            //- small.text-muted.float-right [{{order.status_text}}]
            span {{order.name}}
            span.text-muted.float-right [{{order.status_text}}]
          //- p.text-muted.p-1(style='white-space: pre; word-wrap: nowrap; ') {{order.memo || '고객메모없음'}}
          div
            strong.text-muted.input-field-overlay 이름
            input.form-control.rounded-0.form-field(type='text' v-model='order.name' ref='order-input' @blur='form_touched')
          div
            strong.text-muted.input-field-overlay 휴대폰
            input.form-control.rounded-0.form-field(type='text' v-model='order.phone' @blur='form_touched')
          div
            strong.text-muted.input-field-overlay 이메일
            input.form-control.rounded-0.form-field(type='text' v-model='order.email' @blur='form_touched')
          div
            strong.text-muted.input-field-overlay 메모
            textarea.form-control.form-field.rounded-0.border-0(type='text' v-model='order.memo' rows=10)
          div.clearfix(v-for='(field, idx) in order.fields')
            strong.text-muted.input-field-overlay(@click='edit_field_label(field, idx)') {{field.label}}
            input.form-control.rounded-0.form-field(
              v-if='field.format == "text"'
              :ref='`OrderField${idx}Input`'
              type='text' v-model='field.value' @blur='form_touched')
            //- div.float-left.border.bg-white.shadow.rounded(style='position: relative; top: -10px; left: 0px; width: 100px; height: 100px; margin-bottom: -100px')
              button.btn.btn-light.btn-block.text-left(type='button' style='font-size: 12px;font-weight: bold;color: #333;' @click='edit_field_label(field, idx)') Rename
              button.btn.btn-light.btn-block.text-left(type='button' style='font-size: 12px;font-weight: bold;color: #333;' @click='delete_field(field, idx)') Delete

          div.border-bottom.clearfix
            button.btn.bg-white.btn-light.text-muted(style='font-size: 12px; font-weight: bold' type='button' @click.prevent='add_field()') + 필드추가
            //- button.btn.bg-white.btn-light.text-muted.float-right(style='font-size: 12px; opacity: 0.5' type='button' @click.prevent='add_field_value()') 내용 입력
            //- strong.text-muted.input-field-overlay
            //- input.form-control.text-right.rounded-0.form-field(type='text' disabled)
          //- div
            pre {{order.fields}}
          .form-group
            label status:
            span {{order.status_text}}
          .form-group
            label tags:
            span {{order.tags}} (편집기 넣기)

          //- div.container-menu-backdrop
          //- div.container-menu
            strong wow
          //- b-popover(target='popover-1' placement='bottom' triggers='hover focus' custom-class='shadow border-light')
            //- template.border(#title).bg-dark 알림 목록
            div.p-4
              //- (style='width: 400px; height: 340px; overflow: scroll')
              div.text-center(style='width: 200px !important; height: 10rem') 알림이 없습니다.


          button.btn-block.p-4(type='submit') 저장

          //- h1 (custom field)
    .col-md
      //- .bg-white.p-2.border(style='font-size: 12px')
        p 담당자로그

          p.mb-0(style='white-space: pre') {{comment.origin_text}}

      .bg-white.mt-2.border(style='font-size: 12px; height: 80vh; overflow: scroll;')
        p 응대내역
        table.table#tableLog
          thead
            tr.border-0
              td.border-0.bg-light.shadow-sm(style='width: 140px; outline: solid 1px #ccc; position:sticky; top:0px;') 종류/작성자/일시
              td.border-0.bg-light.shadow-sm(style='outline: solid 1px #ccc; position:sticky; top:0px;') 내용
          tbody
            tr(v-for='row in histories' :key='row.id')
              //- span.text-muted {{row.created_at | datetime }}
              template(v-if='row.category == "comment"')
                td 담당자 로그
                  br
                  strong {{ username(row.user_id ) }}
                  br
                  span.text-muted {{row.created_at | datetime }}
                  span.text-muted.d-block(v-if='row.updated_at') {{row.updated_at | datetime }} (edited)
                td
                  p.mb-0(style='white-space: pre-wrap;' v-once) {{row.origin_text}}
                  button(type='button' @click='delete_comment(row)') &times;
                  button(type='button' @click='row.editing = !row.editing') edit
                  div(v-show='row.editing')
                    textarea(v-model='row.origin_text')
                    button(type='button' @click='update_comment(row)') save
              template(v-else-if='row.category == "sms"')
                td 문자 SMS
                  br
                  strong {{ username(row.user_id ) }}
                  br
                  span.text-muted {{row.created_at | datetime }}
                  span.text-muted.d-block(v-if='row.updated_at') {{row.updated_at | datetime }} (edited)
                td
                  p.mb-0.bg-light.rounded.p-2(style='white-space: pre-wrap;' v-once) {{row.origin_text}}
                    br
                    small.text-muted {{row.origin_json}}
                    router-link.float-right(:to='`/property/${property_id}/views/message/sent`') 그룹발송 조회
                  //- button(type='button' @click='delete_comment(row)') &times;
                  //- button(type='button' @click='row.editing = !row.editing') edit
                  //- div(v-show='row.editing')
                  //-   textarea(v-model='row.origin_text')
                  //-   button(type='button' @click='update_comment(row)') save
              template(v-else-if='row.category == "status_color"')
                td.p-0.shadow.table-warning(colspan=2 v-if='row.origin_text=="tomato"') 호출 ON
                  strong {{ username(row.user_id ) }}
                  span.text-muted {{row.created_at | datetime }}
                td.p-0(colspan=2 v-if='row.origin_text==""' style='padding-bottom: 2rem !important;') 호출 OFF
                  strong {{ username(row.user_id ) }}
                  span.text-muted {{row.created_at | datetime }}
                //- td 호출
                  br
                  strong {{ username(row.user_id ) }}
                  br
                //- td
                  hr.mt-4.mr-4.mb-0.pb-0
                  span.text-dark.p-1.px-2(v-if='row.origin_text == "tomato"' style='color: #888; position: relative; left: 35%; top: -14px; font-size: 12px; background-color: rgb(242, 244, 246) !important') 호출 ON
                  span.text-dark.p-1.px-2(v-else style='color: #888; position: relative; left: 35%; top: -14px; font-size: 12px; background-color: rgb(242, 244, 246) !important') 호출 OFF
              template(v-else)
                th ?
                td
                  pre {{row}}
        div(style='padding-bottom: 2rem')
        div#itemFooter footer
    .col-md
      .bg-white.p-2.border(style='font-size: 12px')
        //- button.btn.bg-light.border.float-right.text-center.order-color-default(type='button' style='position: relative; top: 0px; right: 9px; z-index: 3; margin-bottom: -50px; padding: 0.5rem 1rem' :class="{'order-color-active': (order.status_color)}" @click='set_status_color(order)')
        button.btn.bg-light.border.btn-block.text-center.order-color-default(type='button' style='padding: 0.5rem 1rem' :class="{'order-color-active': (order.status_color)}" @click='set_status_color(order)')
          img.mr-2.ml-1.icon-light(src='/img/standing-up-man.svg' style='width: 20px; opacity: .3')
          br
          span 호출중
      .bg-white.p-2.mt-2.border(style='font-size: 12px')
        p 문자
        div(v-if='sms_config && sms_config.numbers' )
          .alert.alert-warning(v-show='!sms_config.numbers.length')
            strong 알림
            p 발신자 번호 등록이 필요합니다.
            a(:href='`/property/${property_id}/manage/sms`' target='_blank') 새창으로 열기
          a.float-right(href='#' @click.prevent='fetch_sms_config()'): small 새로고침
          strong: small 발신자
          //- select.form-control(v-model='form.sms_sender_phone' :disabled='!sms_config.numbers.length')
          select.form-control(v-model='form.sms_sender_phone' disabled)
            option(v-for='config in sms_config.numbers' :value='config.number') {{config.number}}

          button(type='button' @click='sms_toggle()' disabled) 직접입력 발송

          form(@submit.prevent.stop='send_sms(order)' v-show='sms_active')
            div
              strong.text-muted: small 내용
              textarea.form-control.rounded-0.m-0(v-on:input="form.sms_input = $event.target.value" @keyup='sms_result()' rows=7 :disabled='!form.sms_sender_phone' @blur='form_touched')
            div
              strong.text-muted.input-field-overlay: small 내용
              textarea.form-control.rounded-0.pt-4.m-0(v-model='form.sms_result' rows=7 readonly)
            button.btn.btn-block.bg-light.border(type='submit') 보내기
          p 원터치 발송
          div(v-if='$store.state.message_templates_by_status && order.status_text')
            span.d-block(v-for='row in $store.state.message_templates_by_status[order.status_text]' )
              button(type='button' @click='send_sms_template(row)' disabled title='closed') {{row.name}}
              router-link(:to='`/property/${property_id}/views/message/new?oids=${ JSON.stringify([order.id]) }&template_id=${row.id}`' target='_blank') {{row.name}}

      .bg-white.p-2.mt-2.border(style='font-size: 12px')
        p 이메일
      .bg-white.p-2.mt-2.border(style='font-size: 12px')
        p 담당자로그

        form(@submit.prevent='submit_comment')
          textarea.form-control(v-model='form.comment' rows=3)
          button(type='submit') 추가

  .bg-white.p-4.mt-4.border

  .bg-white.p-4.mt-4.border(style='font-size: 12px')
    p 변경내역
    div(v-for='log in logs')
      span.text-muted {{log.created_at | datetime }}
      strong.ml-1 {{log.field || ' '}}
      span.ml-1 edited from
      strong.text-primary.ml-1 {{log.before || ' '}}
      span.ml-1 to
      strong.text-primary.ml-1 {{log.after || ' '}}
      span.ml-1 by {{ username(log.user_id) }}
    //- button.btn.btn-default.btn-block.bg-light 내용 더보기

</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'sf_id', 'order_id'],
  components: {

  },
  computed: {

  },
  watch: {
    'order_id'(after) {
      this.fetch_order()
      this.fetch_order_log()
      this.fetch_order_history()
    },
    '$store.state.message_templates_by_status'(after){
      this.load_message_template()
    }

    // '$route.query.q'(after) {

    // },
  },
  data() {
    return {
      // status_flows_loaded: () => { return this.status_flows },
      order: {},
      logs: [],
      histories: [],
      form: {
        sms_sender_phone: '',
        sms_result: '',
        sms_input: '',
      },
      sms_active: false,
      sms_config: {},
      sms_template: [],
    }
  },
  async mounted() {
    this.fetch_order()
    this.fetch_order_log()
    this.fetch_order_history()

    this.fetch_sms_config()
  },
  methods: {
    async fetch_order() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/orders/${this.order_id}`)
        if (r?.data?.message != 'ok') throw new Error('가져오기 실패')

        this.order = r.data.order

        this.load_message_template()
      } catch (error) {
        console.log(error.stack)
      }
    },
    async fetch_order_log() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/orders/${this.order_id}/logs`)
        if (r?.data?.message != 'ok') throw new Error('가져오기 실패')

        this.logs = r.data.rows
      } catch (error) {
        console.log(error.stack)
      }
    },
    async fetch_order_history() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/orders/${this.order_id}/histories`)
        if (r?.data?.message != 'ok') throw new Error('가져오기 실패')

        this.histories = r.data.rows.map(e => {
          e.editing = false
          return e
        })
        setTimeout(() => {
          document.querySelector('#tableLog').parentNode.scrollTop = document.querySelector('#tableLog').offsetHeight
        }, 300);
      } catch (error) {
        console.log(error.stack)
      }
    },
    async fetch_sms_config() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/sms_config`)
        this.sms_config = r.data.sms_config
        if (!this.sms_config.numbers) {
          this.sms_config.numbers = []
        } else {
          this.form.sms_sender_phone = this.sms_config.numbers[0].number
        }
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: 'failed to load: sms config',
        })
      }
    },
    load_message_template() {
      if (this.order && this.order.status_text) {
        console.log(this.$store.state.message_templates_by_status)
        this.sms_template = this.$store.state.message_templates_by_status[this.order.status_text]
        this.sms_template_all = this.$store.state.message_templates_by_status['전체']
      }
    },
    async submit() {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/orders/${this.order_id}`, this.order)
        if (r?.data?.message != 'ok') throw new Error('저장 실패')

        // this.fetch_order()
        this.fetch_order_log()
      } catch (error) {
        console.log(error.stack)
      }
    },
    async submit_comment() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/orders/${this.order_id}/comments`, {
          text: this.form.comment,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패')

        // this.fetch_order()
        // this.fetch_order_log()
        this.fetch_order_history()
      } catch (error) {
        console.log(error.stack)
      }
    },
    async update_comment(comment) {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/orders/${this.order_id}/comments/${comment.id}`, {
          origin_text: comment.origin_text,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패')

        // this.fetch_order()
        // this.fetch_order_log()
        this.fetch_order_history()
      } catch (error) {
        console.log(error.stack)
      }
    },
    async delete_comment(comment) {
      if(!confirm('proceed?')) return false
      try {
        const r = await this.$http.delete(`/v1/property/${this.property_id}/views/orders/${this.order_id}/comments/${comment.id}`)
        if (r?.data?.message != 'ok') throw new Error('삭제 실패')

        this.fetch_order_history()
      } catch (error) {
        console.log(error.stack)
      }
    },

    async set_status_color() {
      try {
        const status_color = this.order.status_color == 'tomato' ? '' : 'tomato'
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/${this.order.id}/set_status_color`, {
          status_color,
        })
        if (r?.data?.message != 'ok') throw new Error('호출변경 실패')

        // this.order.status_color = status_color
        this.fetch_order()
        this.fetch_order_history()

        // this.$refs['order_memo_input']?.focus()
        // this.$store.dispatch('status flows count', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    add_field() {
      const label = prompt('field name?')
      if (!label) return false

      this.order.fields.push({
        label,
        format: 'text',
        value: '',
      })
      const idx = this.order.fields.length -1
      setTimeout(() => {
        const ref = this.$refs[`OrderField${idx}Input`]
        if (ref && ref[0]) {
          ref[0].focus()
        }
      }, 300);
    },

    add_field_value() {
      const idx = this.order.fields.length

      this.order.fields.push({
        label: '필드 '+(idx+1),
        format: 'text',
        value: '',
      })

      setTimeout(() => {
        const ref = this.$refs[`OrderField${idx}Input`]
        if (ref && ref[0]) {
          ref[0].focus()
        }
      }, 300);
    },

    edit_field_label(field, index) {
      const label = prompt('필드명을 변경합니다. (delete 입력시 삭제)', field.label)
      if (!label) return false
      if (label == 'delete') {
        return this.delete_field_label(field, index)
      }
      this.order.fields[index].label = label
    },

    delete_field_label(field, index) {
      this.order.fields = this.order.fields.filter((e,i) => {
        return i != index
      })
    },

    username(id) {
      return this.$store.state.users_by_id[id]?.name || '(이름없음)'
    },

    has_changed() {
      return false
    },

    sms_result() {
      let text = '' + this.form.sms_input
      text = text.replace('@고객명', this.order.name)

      this.$nextTick(() => {
        this.form.sms_result = text
      })
    },

    form_touched() {
      if (this.has_changed()) {
        window.FormTouched = 'Y'
      } else {
        window.FormTouched = 'N'
      }
      console.log(window.FormTouched)
    },

    // get_status_flows() {
    //   return this.status_flows
    // }
  },
}
</script>
